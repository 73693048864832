$bg: #211A1E;
$secondary-bg: #0D1317;
$primary: #5171A5;
$title: #F39A2F;
$font: #F2EFE9;

body {
  background-color: $bg;
  margin: 0;
  height: 100%;
  color: $font;
}