@import "colors";

.index {
  #header {
    display: flex;
    flex-flow: column;
    align-items: center;

    p {
      margin: 0;
      color: red;

      &:last-of-type {
        color: green;
      }
    }

    h1 {
      max-width: fit-content;
      font-variant: small-caps;
      color: #f39a2f;
    }

    textarea, button {
      width: 500px;
      margin: 0 15px;
      border-radius: .5em;

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    textarea {
      height: 110px;
      border: none;
      outline: none;
      color: $font;
      background-color: #2d2400;
      resize: none;
    }

    button {
      margin: 5px;
      padding: 10px 0;
      background-color: #301f0b;
      color: #f39a2f;
      border: none;
      font-weight: bold;
      font-size: 17px;
      text-transform: uppercase;

      &:hover {
        background-color: #402b12;
      }
    }
  }

  .container {
    display: flex;
    flex-flow: row;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;

    @media (max-width: 1024px) {
      flex-flow: column;
      align-items: center;
    }

    div {
      width: 400px;
      background-color: $secondary-bg;
      padding: 15px;
      border-radius: 1em;
      margin-bottom: 20px;

      @media (max-width: 450px) {
        width: 100%;
        margin: 5px;
        box-sizing: border-box;

        &:last-of-type {
          margin-bottom: 20%;
        }
      }

      p {
        border-radius: .5em;
        background-color: $primary;
        width: 100%;
        padding: 10px;
        margin-bottom: 5px;
        box-sizing: border-box;
      }
    }
  }
}