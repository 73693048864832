@import "colors";

.admin {
  input {
    width: 70px;
    height: 20px;
  }

  div:first-of-type {
    background-color: $primary;
    height: 45px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    color: $font;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 25px;
    top: 0;
    position: sticky;

    > span > p:last-of-type {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }

    @media (max-width: 960px) {
      flex-flow: column;
      height: fit-content;
      justify-content: unset;
      align-items: flex-start;

      h1 {
        font-size: 1.3rem;
      }

      > span > p:last-of-type {
        float: left;
      }
    }

    * {
      margin: 0;
    }
  }

  > div:nth-of-type(2) {
    @media (max-width: 960px) {
      overflow: auto;
      table input {
        width: 20px;
      }
    }
  }

  table {
    border-collapse: collapse;
    margin: auto;
    tbody {
      tr {
        &:hover td {
          background-color: $secondary-bg;
        }

        background-color: #3f3f3f;
        padding: 20px 5px;

        &:nth-of-type(odd) {
          background-color: #363636;
        }

        td {
          padding: 5px 2px;

          &:nth-of-type(1) {
            width: 500px;
          }

          &:nth-of-type(5) {
            button {
              background-color: #e32d2d;
              border-radius: 0;
              border: none;
              font-size: 1em;
              font-weight: bold;
              margin: 0 5px;

              &:hover {
                background-color: #af1616;
              }
            }
          }
        }
      }
    }
  }
}